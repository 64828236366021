<template>
  <div class="group-event-add">
    <b-card>
      <b-form
        :class="{ 'invalid-form': isSubmitted }"
        @submit.prevent
      >
        <b-row>
          <b-col md="4">
            <select-group
              v-model="dataInfo.eventTypeId"
              :value="dataInfo.eventTypeId"
              :label="'Type'"
              :options="eventTypeList"
              option-value="id"
              option-label="name"
              image="/images/members/eventType.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.code"
              :value="dataInfo.code"
              :label="'Event Code'"
              image="/images/members/details.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.startDate"
              :value="dataInfo.startDate"
              :label="'Start Date'"
              :type="'date'"
              image="/images/members/startDate.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.endDate"
              :value="dataInfo.endDate"
              :label="'End Date'"
              :type="'date'"
              image="/images/members/endDate.svg"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.amount"
              :value="dataInfo.amount"
              :label="'Amount'"
              image="/images/members/amount.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <input-group
              v-model="dataInfo.assistanceDesc"
              :value="dataInfo.assistanceDesc"
              :label="'Details'"
              image="/images/members/details.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <select-group
              v-model="dataInfo.status"
              :value="dataInfo.status"
              :label="'Status'"
              :options="$manMudra.constants.eventStatus"
              image="/images/members/status.svg"
              :make-required="true"
            />
          </b-col>
          <b-col md="4">
            <select-group
              v-model="dataInfo.giveOrTake"
              :value="dataInfo.giveOrTake"
              :label="'Type'"
              :options="$manMudra.constants.eventType"
              image="/images/members/status.svg"
            />
          </b-col>
          <b-col
            md="12"
            class="text-center mt-2"
          >
            <button
              class="btn btn-primary"
              @click="saveEvents"
            >
              Save
            </button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BForm,
} from 'bootstrap-vue'
import InputGroup from '@/@core/layouts/shared/InputGroup.vue'
import SelectGroup from '@/@core/layouts/shared/SelectGroup.vue'
import {
  appEventById,
  appEventTypes,
  appUpdateEvent,
} from '@/@core/services/groups'
import {
  hideLoader,
  showDangerNotification,
  showLoader,
  showSuccessNotification,
} from '@/@core/comp-functions/ui/app'
import { goToPath } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    InputGroup,
    SelectGroup,
  },
  data() {
    return {
      dataInfo: {},
      eventTypeList: [],
      eventId: this.$route.params.eventId,
      isSubmitted: false,
    }
  },
  computed: {
    unionSelected() {
      return this.$store.getters['groups/currentUnion']
    },
  },
  mounted() {
    this.getEventTypes()
    if (this.eventId) {
      this.getEventById()
    }
  },
  methods: {
    getEventById() {
      showLoader()
      appEventById({
        id: this.eventId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.dataInfo = data.data
          if (this.dataInfo.giveOrTake === 'GIVE') {
            this.dataInfo.giveOrTake = 0
          } else if (this.dataInfo.giveOrTake === 'TAKE') {
            this.dataInfo.giveOrTake = 4
          } else {
            this.dataInfo.giveOrTake = 2
          }
          if (this.dataInfo.startDate) {
            this.dataInfo.startDate = this.$helpers.formatDate(
              this.dataInfo.startDate,
            )
          }
          if (this.dataInfo.endDate) {
            this.dataInfo.endDate = this.$helpers.formatDate(
              this.dataInfo.endDate,
            )
          }
        } else {
          showDangerNotification(this, data.message)
        }
        hideLoader()
      })
    },
    getEventTypes() {
      appEventTypes().then(({ data }) => {
        if (data.succeeded) {
          this.eventTypeList = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    saveEvents() {
      this.isSubmitted = true
      this.$nextTick(() => {
        if (window.ManMudra.validations.get()) {
          window.ManMudra.validations.focus()
          return
        }
        showLoader()
        this.isSubmitted = false
        this.dataInfo.unionId = this.unionSelected.id
        // eslint-disable-next-line no-nested-ternary, eqeqeq
        this.dataInfo.giveOrTake = this.dataInfo.giveOrTake == 0 ? 'GIVE' : this.dataInfo.giveOrTake == 4 ? 'TAKE' : null
        appUpdateEvent(this.dataInfo).then(({ data }) => {
          if (data.succeeded) {
            goToPath(this, 'group-event-list')
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
      })
    },
  },
}
</script>
